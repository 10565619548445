
import { ApiOrdersPaymentRequestPostRequest, OrderPaymentDto, PagedResultOfOrderPaymentViewModel, OrdersViewModelCustomerPaymentMethods, CustomerPaymentMethodsDto, OrderPaymentViewModelFailedPaymentResult, OrderPaymentViewModel } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import { PropType } from "vue/types/v3-component-props";
import { formatDate } from "@/utils/formatDate";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { ordersIdPaymentsExportGet } from "@/api/payments";
import { ordersPaymentRequestPost } from "@/api/order-payment-requests";
import { orderPaymentMethodUpdate } from "@/api/orders";

@Component({
  name: "Products",
  components: { DialogBox, Pagination },
  props: {
    data: { required: true, type: Object as PropType<PagedResultOfOrderPaymentViewModel> },
    orderId: { required: true, type: String },
    orderRef: { required: true, type: String },
    paymentMethod: { required: true, type: Object as PropType<OrdersViewModelCustomerPaymentMethods> },
    allPaymentMethods: { required: true, type: Array as PropType<CustomerPaymentMethodsDto[]> },
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'danger',
        null: 'danger',
        true: 'success',
      }
      return statusMap[status]
    }
  },
  watch: {  
    'createPaymentLinkData.amount': {  
      handler: 'validateAmount',  
      immediate: true  
    }  
  }
})
export default class extends Vue {
  pageSize = 20;
  pageNumber = 1;
  dialogResendVisible = false;
  dialogErrorVisible = false;
  selectedResendRow: ApiOrdersPaymentRequestPostRequest | null = null
  selectedErrorRow: OrderPaymentViewModelFailedPaymentResult | null = null
  currentPaymentMethod: string = ""
  dialogCreateVisible = false;  
  createPaymentLinkData = {    
    amount: '',
    shouldTokenizeCard: false    
  };  
  validationError: string | null = null;

  get canSubmitPaymentLink() {  
    return this.createPaymentLinkData.amount > '';  
  }

  get isAmountValid() {  
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/;  
    const amount = this.createPaymentLinkData.amount;  
    return regex.test(String(amount)) && amount > '';  
  }

  get amountErrorMessage() {  
    if (this.createPaymentLinkData.amount === null || this.createPaymentLinkData.amount === '') {  
      return "Please input a valid Payment Amount";  
    } else if (!this.isAmountValid) {  
      return "Please input a numeric value without any non-numeric characters";  
    }  
    return null;  
  }

  validateAmount() {  
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/;  
    const amount = this.createPaymentLinkData.amount.trim(); 
    if (!amount) {  
      this.validationError = "Please input a valid Payment Amount";  
    } else if (!regex.test(amount)) {  
      this.validationError = "Please input a numeric value without any non-numeric characters";  
    } else {  
      this.validationError = null;  
    }  
  }   

  handleDialogVisibleUpdate(value: boolean) {  
    this.dialogCreateVisible = value;  
    if (!value) {  
  
      this.resetCreatePaymentLinkForm();  
    }  
  }

  resetCreatePaymentLinkForm() {  
    this.createPaymentLinkData = {  
      amount: '',
      shouldTokenizeCard: false  
    };  
  }

  resetValidationError() {  
    this.validationError = null;  
  }

  showCreatePaymentLinkForm() {  
    this.dialogCreateVisible = true;  
  }  

  handleDate(date: any) {
    return formatDate(date);
  }

  checkCurrentMethod() {
    if (!this.currentPaymentMethod) {
      this.currentPaymentMethod = this.$props.paymentMethod.id
    }
  }

  handleCardName(paymentType: CustomerPaymentMethodsDto) {
    return (paymentType.cardType ? paymentType.cardType : 'Card') + ' ' +
      (paymentType.last4Digits ? `ending in ${paymentType.last4Digits}` : '') + ' ' +
      (paymentType.expiryMonth ? `(expires ${paymentType.expiryMonth}/${paymentType.expiryYear})` : '')
  }

  async exportPayments() {
    await ordersIdPaymentsExportGet(this.$props.orderId).then(async (res) => {
      await this.saveXSL(res, `${this.$props.orderRef}_Payments`);
    })
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.$emit('pageChange', this.pageNumber, this.pageSize);
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.$emit('pageChange', this.pageNumber, this.pageSize);
  }

  disabledDate(date: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison

    // Provide only future dates
    return date < today;
  };

  async sendPaymentLink() {  
    if(this.selectedResendRow) {
      await ordersPaymentRequestPost(this.selectedResendRow).then(() => {
        this.dialogClose()
      }).catch(() => {
        this.dialogClose()
      })
    }
    this.dialogClose()
  }

  openPaymentLinkDialog(row: OrderPaymentViewModel) {
    this.selectedResendRow = {
      ordersId: row.ordersId,
      orderProductsId: row.orderProductsId,
      amount: row.amount,
      requestedProcessDate: null
    }
    this.dialogResendVisible = true
  }

  openErrorDialog(row: OrderPaymentViewModel) {
    this.selectedErrorRow = row.failedPaymentResult || null;
    if(this.selectedErrorRow) {
      this.dialogErrorVisible = true
    }
  }

  dialogClose() {
    this.dialogResendVisible = false;  
    this.dialogErrorVisible = false;  
    this.dialogCreateVisible = false;
    this.selectedResendRow = null;  
    this.selectedErrorRow = null;  
    this.createPaymentLinkData = {  
      amount: '', 
      shouldTokenizeCard: false  
    };
    this.resetValidationError();
  }

  updatePaymentMethod() {
    this.currentPaymentMethod = this.$props.paymentMethod.id
    orderPaymentMethodUpdate({ id: this.$props.orderId, customerPaymentMethodId: this.$props.paymentMethod.id})
    this.$emit('methodChange', this.pageNumber, this.pageSize);
  }

  async saveXSL(data: any, title: string) {
    var assetBlob = new Blob([data], { type: "application/xlsx" });
    var assetUrl = URL.createObjectURL(assetBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = assetUrl;
    const fileName = `${title}_${formatDate(new Date() as any, '_')}.xlsx`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  async submitPaymentLink() {    
    if (this.validationError) {    
      return;    
    }   
      
    const payload: ApiOrdersPaymentRequestPostRequest = {    
      ordersId: this.$props.orderId,    
      amount: parseFloat(this.createPaymentLinkData.amount),
      isManualPayment: !this.createPaymentLinkData.shouldTokenizeCard     
    };
      
    try {  
      await ordersPaymentRequestPost(payload);  
      this.dialogClose();  
    } catch (error) {  
      ({ message: "An error has occurred.", showClose: true })
      this.dialogClose();
    }  
  }
}
