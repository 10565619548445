
import { CancelationReasonsEnum, ApiOrdersProductsMsisdnCancelDeleteRequest, CancelationReasonsDto, OrderProductsDto, OrderProductStatusesEnum, OrdersViewModel, ProductsListViewModel } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import { PropType } from "vue/types/v3-component-props";
import { formatDate } from "@/utils/formatDate";
import DialogBox from "@/components/DialogBox.vue";
import { migrateDdlGet, topUpDdlGet, cancelDdlGet } from "@/api/products";
import { ordersProductCancel, ordersProductMigratePut, ordersProductSuspend, ordersProductTopUpPut, ordersProductsMsisdnResumeServicePut, updateOrderProductIccid } from "@/api/orders";
import { Message } from "element-ui";
import { hasClaim } from "@/utils/claims";
import { formatName, orderProductStatusesFilter } from "@/utils";
import RegisterVue from "@/views/auth/Register.vue";

type ActionName = "topUp" | "migrate" | "cancel" | "suspend" | "reactivate" | "updateIccid";
type ActionDetail = {
  callback: Function;
  message: string;
  title: string;
  ddl: ProductsListViewModel[] | CancelationReasonsDto[] | null;
}

@Component({
  name: "Products",
  components: { DialogBox },
  props: {
    data: { required: true, type: Object as PropType<OrdersViewModel> },
  },
  filters: {
    statusFilter: (status:OrderProductStatusesEnum) => orderProductStatusesFilter(status)
  }
})
export default class extends Vue {
  newIccid = "";
  filterReference = "";
  dialogVisible: boolean = false;
  dialogSelectedDdlId: number | string | null = null;
  dialogSelectedProduct: OrderProductsDto | null = null;
  dialogSelectedAction: ActionName | null = null;
  actionTitle: string = "";
  actions: Record<ActionName, ActionDetail> = {
    "topUp": {
      callback:() => this.topUp(),
      message: "",
      title: "Top Up",
      ddl: []
    },
    "migrate": {
      callback:() => this.migrate(),
      message: "",
      title: "Upgrade / Downgrade",
      ddl: []
    },
    "cancel": {
      callback:() => this.cancel(),
      message: "",
      title: "Cancel",
      ddl: []
    },
    "suspend": {
      callback:() => this.suspend(),
      message: "",
      title: "Suspend",
      ddl: null
    },
    "reactivate": {  
      callback:() => this.reactivate(),  
      message: "Are you sure you want to reactivate this product?",  
      title: "Reactivate",  
      ddl: null  
    },
    "updateIccid": {  
      callback:() => this.updateIccid(),  
      message: "Enter the new ICCID for this product:",  
      title: "Update ICCID",  
      ddl: null  
    }  
  }
  userHasPermisson = false;

  getCancelationReasonEnum(id: number): CancelationReasonsEnum | null {  
  const mapping: Record<number, CancelationReasonsEnum> = {  
    1: CancelationReasonsEnum.NoLongerUsesProduct,  
    2: CancelationReasonsEnum.CannotAfford,  
    3: CancelationReasonsEnum.PoorServiceOrCoverage,  
    4: CancelationReasonsEnum.NonPayment,  
    5: CancelationReasonsEnum.TheftOrLossOfTheSim,  
    6: CancelationReasonsEnum.PrefersCompetitorProduct,  
    7: CancelationReasonsEnum.ServiceRelated,  
    8: CancelationReasonsEnum.NoCoverage,  
    9: CancelationReasonsEnum.FraudulentOrder,  
    10: CancelationReasonsEnum.CustomerDeceased,  
    11: CancelationReasonsEnum.DuplicateOrder,  
    12: CancelationReasonsEnum.OrderAbandoned,  
    13: CancelationReasonsEnum.UnableToActivateOnNetwork,  
  };  
  return mapping[id] || null;  
  }  

  async created() {
    this.userHasPermisson = await hasClaim("ManageOrders")
    this.actions.topUp.ddl = await topUpDdlGet();
    this.actions.cancel.ddl = await cancelDdlGet();
  }

  get isIccidInvalid() {  
    const nonNumeric = /\D/;
    return nonNumeric.test(this.newIccid) || this.newIccid.length < 19;  
  }  

  handleDate(date: any) {
    return formatDate(date);
  }

  handleName(name:string) {
    return formatName(name)
  }

  handleAction(product:OrderProductsDto, action: ActionName) {
    this.dialogSelectedProduct = product;
    this.dialogSelectedAction = action;
    this.newIccid = "";
    this.handelActionDetail(product, action);    
    this.dialogVisible = true;
  }

  async handelActionDetail(product:OrderProductsDto, action: ActionName) {
    switch(action) {
      case "topUp": 
        this.actions[action].message = `<div><b>MSISDN:</b> ${product.msisdn}</div>`;
        this.dialogSelectedDdlId = this.actions[action].ddl?.[0].id || null;
        break; 
      case "migrate":
        this.actions[action].ddl = await migrateDdlGet(product.products.id);
        this.actions[action].message = `
          <div><b>MSISDN:</b> ${product.msisdn}</div>
          <div><b>Current:</b> ${ product.products.vascoDealDescription} @ R${ product.products.vascoPrice}pm</div>
        `
        // this.dialogSelectedDdlId = product.products.id;
        break; 
      case "cancel":
        this.actions[action].message = `
          <div><b>MSISDN:</b> ${product.msisdn}</div>
          <div>Are you sure you want to cancel this product?</div>
        `
        this.dialogSelectedDdlId = this.actions[action].ddl?.[0].id || null;
        break; 
      case "suspend":
        this.actions[action].message = `
          <div><b>MSISDN:</b> ${product.msisdn}</div>
          <div>Are you sure you want to suspend this product?</div>
        `
        this.dialogSelectedDdlId = null;
        break; 
      default: 
        break; 
    }
  }

  handleActionClose() {
    this.dialogSelectedProduct = null;
    this.dialogSelectedAction = null;
    this.dialogSelectedDdlId = null;
    this.actions.migrate.ddl = [];
    this.dialogVisible = false;
  }

  handleDialogVisibilityChange(newValue: boolean) {  
    this.dialogVisible = newValue;  
  }

  async topUp() {
    debugger
    if(this.dialogSelectedProduct?.msisdn) {
      const payload = {
        productsId: this.dialogSelectedDdlId as number,
        msisdn: this.dialogSelectedProduct.msisdn,
        isTopUp: true,
        ordersId: this.dialogSelectedProduct.ordersId
      }

      await ordersProductTopUpPut(this.dialogSelectedProduct.msisdn, payload)
      .then(() => {
        this.$emit('reload');
        this.handleActionClose();
      })
      .catch(() => {
        this.handleActionClose();
      }) 
    } else {
      Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true })
      this.handleActionClose();
    }
  }

  async migrate() {
    debugger
    if(this.dialogSelectedProduct?.msisdn) {
      const payload = {
        productsId: this.dialogSelectedDdlId as number,
        msisdn: this.dialogSelectedProduct.msisdn,
        isTopUp: false,
        ordersId: this.dialogSelectedProduct.ordersId
      }

      await ordersProductMigratePut(this.dialogSelectedProduct.msisdn, payload)
      .then(() => {
        this.$emit('reload');
        this.handleActionClose();
      })
      .catch(() => {
        this.handleActionClose();
      }) 
    } else {
      Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true })
      this.handleActionClose();
    }
  }

  async reactivate() {  
    if(this.dialogSelectedProduct?.msisdn) { 
      
      await ordersProductsMsisdnResumeServicePut(this.dialogSelectedProduct.msisdn)  
        .then(() => {  
          Message.success({ message: "Product reactivation initiated.", showClose: true });  
          this.$emit('reload');  
          this.handleActionClose();  
        })  
        .catch(() => {  
          this.handleActionClose();  
        });  
    } else {  
      Message.error({ message: "An error has occured - MSISDN is required for reactivation.", showClose: true });  
      this.handleActionClose();  
    }  
  }  

  async cancel() {
    debugger
    if (this.dialogSelectedProduct?.msisdn) {

      const cancelationReasonEnumValue = this.getCancelationReasonEnum(Number(this.dialogSelectedDdlId));  
        
      if (!cancelationReasonEnumValue) {  
        Message.error({ message: "Invalid cancellation reason selected.", showClose: true });  
        return this.handleActionClose();  
      }  
      
      const payload: ApiOrdersProductsMsisdnCancelDeleteRequest = {  
        msisdn: this.dialogSelectedProduct.msisdn,  
        cancelationReasonsId: cancelationReasonEnumValue,  
      }; 

        await ordersProductCancel(this.dialogSelectedProduct.msisdn, payload)
            .then(() => {
                this.$emit('reload');
                this.handleActionClose();
            })
            .catch(() => {
                this.handleActionClose();
            });
      } else {
          Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true });
          this.handleActionClose();
      }
  }

  async updateIccid() {  
  // Ensure the dialogSelectedProduct and newIccid are set  
  if (this.dialogSelectedProduct && this.newIccid) {  
    // Call the updateOrderProductIccid function with the product's ID and the new ICCID  
    await updateOrderProductIccid(this.dialogSelectedProduct.id, this.newIccid)  
      .then(() => {  
        Message.success({ message: "ICCID updated successfully!", showClose: true });  
        this.$emit('reload'); // Emit a reload event to refresh data  
        this.handleActionClose(); // Close the dialog  
      })  
      .catch((error) => {  
        // Handle any errors that occur during the update  
        Message.error({ message: "Failed to update ICCID. " + error.message, showClose: true });  
        this.handleActionClose();  
      });  
  } else {  
    // Show an error message if the product ID or new ICCID is missing  
    Message.error({ message: "An error has occurred - Order Product ID and new ICCID are required.", showClose: true });  
    this.handleActionClose();  
  }  
} 

  async suspend() {
    debugger
    if(this.dialogSelectedProduct?.msisdn) {
      await ordersProductSuspend(this.dialogSelectedProduct.msisdn)
      .then(() => {
        this.$emit('reload');
        this.handleActionClose();
      })
      .catch(() => {
        this.handleActionClose();
      }) 
    } else {
      Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true })
      this.handleActionClose();
    }
  }

  paymentHistory(product: OrderProductsDto): void {
    console.log('paymentHistory', product.id, product.ordersId);
    //paymentHistory
  }
}
